@import '../common.scss';

.EndScreen {
    text-align: center;

    cursor: default;

    margin-top: 16px;
    margin-bottom: 32px;

    color: $light-text-color;

    @media screen and (max-width: $small-screen-width) {
        font-size: 10px;
    }

    padding: 0 20px;

    .Score {
        font-size: 48px;
        font-weight: 600;

        @media screen and (max-width: $medium-screen-width) {
            font-size: 24px;
        }

        @media screen and (max-width: $small-screen-width) {
            font-size: 16px;
        }

        margin-top: 3px;
        margin-bottom: 1px;
    }

    .Continue {
        b {
            margin-left: 5px;
            margin-left: 5px;
        }

        &.hide {
            color: $light-background;
        }

        &.show {
            cursor: pointer;
        }
    }
}

.DarkMode .EndScreen {
    color: $dark-text-color;

    .Continue.hide {
        color: $dark-background;
    }
}