@import "../common.scss";

.NamedSelect {
    color: $light-text-color;

    width: max-content;
    cursor: pointer;

    font-size: 24px;
    font-weight: 400;

    @media screen and (max-width: $medium-screen-width) {
        font-size: 16px;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    &.Selected {
        opacity: $opacity-selected;
        font-size: 32px;
        font-weight: 600;

        @media screen and (max-width: $medium-screen-width) {
            font-size: 24px;
        }
    }

    &:not(.Selected) {
        opacity: $opacity-unselected;

        &:hover {
            opacity: $opacity-hover;
        }
    }
}

.DarkMode .NamedSelect {
    color: $dark-text-color;
}